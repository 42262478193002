import { useEffect } from 'react';
import BottomNav from './BottomNav'
import pic from '../../images/trentPic.JPG'

export default function About() {

  useEffect(() => {window.scrollTo(0, 0)}, [])
  return (
    <div className="panel left">
      <BottomNav />
      <div className="subpageWrapper">
        <div className='aboutPic'>
          <img className='myPic' src={pic}/>
        </div>
            <br></br>
            <h1>
              A little bit about me...
            </h1>
            <br></br>
            <p>
              Hi! My name is Trent Currie and I'm a software engineer based out of Los Angeles, CA. 
              <br></br>
              I am experienced in developing modular full-stack JavaScript applications using React, Node, Express. I am also a data geek and love relational databases (check out the SQURL tab).
            </p>
      
      </div>
    </div>
  );
}