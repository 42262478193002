import { useEffect } from 'react';
import {AiFillGithub} from 'react-icons/ai'
import BottomNav from './BottomNav'

export default function ShopSmart() {

  useEffect(() => {window.scrollTo(0, 0)}, [])
  return (
    <div className="panel left">
      <div className='subpageWrapper'>
      <BottomNav />
        <h1>
          ShopSmart
        </h1>
        <a className="socialLink" target="_blank" rel="noopener noreferrer" href="https://github.com/ShopSmarter/ShopSmart">
          <AiFillGithub/>
        </a>
      </div>
    </div>
  );
}