import PubSub from "../images/pubSubWhite.png"
import ShopSmart from "../images/shopSmartWhite.png"
import Squrl from "../images/squrlWhite.png"
import { Link } from 'react-router-dom'

export default function RightPanel() {
  return (
    <div className="portfolioWrapper">
      <div className="projects">
        <Link className="projectLinks" to='/Squrl'>
          <img src={Squrl} alt="SQURL"/>
        </Link>
        <Link className="projectLinks" to='/PubSub'>
          <img src={PubSub} alt="Pub Sub Trivia"/>
        </Link>
        <Link className="projectLinks" to='ShopSmart'>
          <img src={ShopSmart} alt="SmartShop"/>
        </Link>
        <Link className="otherLinks" to='/about'>
          <p>About</p>
        </Link>
        <a className="otherLinks" target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/1V0kBstUbU5f7Q1uZwX-4zWVzc2Qfc3Ys/view?usp=sharing'>
          <p>Resume</p>
        </a>
        <a className="otherLinks" target="_blank" rel="noopener noreferrer" href="" href="mailto:trentdcurrie@gmail.com">
          <p>Contact</p>
        </a>
      </div>
    </div>
  );
}