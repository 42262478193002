import { useEffect } from 'react';

export default function Resume() {

  useEffect(() => {window.scrollTo(0, 0)}, [])
  return (
    <div className="panel left">
      <div className='App'>
        <h1>
          Resume
        </h1>
      </div>
    </div>
  );
}