import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import About from './Subpages/About.js'
import PubSub from './Subpages/PubSub.js'
import Resume from './Subpages/Resume.js'
import ShopSmart from './Subpages/ShopSmart.js'
import Squrl from './Subpages/Squrl.js'
import {Switch, Route} from "react-router-dom";

export default function Home() {
  return (
    <div className='panel'>
    <Switch>
      <Route path='/about' component={About}/>
      <Route path='/pubsub' component={PubSub}/>
      <Route path='/resume' component={Resume}/>
      <Route path='/shopsmart' component={ShopSmart}/>
      <Route path='/squrl' component={Squrl}/>
      <Route path='/home' component={Home}/>
      <div className="left">
        <LeftPanel />
      </div>
    </Switch>
      <div className="right">
        <RightPanel />
      </div>
    </div>
  );
}