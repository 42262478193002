import {AiFillGithub} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'

export default function Social() {
  return (
    <div className="social">
      <a className="socialLink" href="https://github.com/trentcurrie" target="_blank" rel="noopener noreferrer">
        <AiFillGithub/>
      </a>
      <a className="socialLink" href="https://www.linkedin.com/in/trentdcurrie/" target="_blank" rel="noopener noreferrer">
        <AiFillLinkedin/>
      </a>
  </div>
  );
}
