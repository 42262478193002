import {AiFillGithub} from 'react-icons/ai'
import BottomNav from './BottomNav'
import { useEffect } from 'react';

export default function PubSub() {

  useEffect(() => {window.scrollTo(0, 0)}, [])
  return (
    <div className="panel left">
      <BottomNav />
      <div className='subpageWrapper'>
        <h1>
          Pub Sub Trivia
        </h1>
        <a className="socialLink" target="_blank" rel="noopener noreferrer" href="https://github.com/Pub-Sub-Trivia/Pub-Sub-Trivia">
          <AiFillGithub/>
        </a>
      </div>
    </div>
  );
}