import Typewriter from 'typewriter-effect';
import Social from './Miscellaneous/Social'

export default function LeftPanel() {
  return (
    <div className="subpageWrapper">
      <div className='alignLeft'>
      <h1>Hello World!</h1>
      <h1>My name is Trent Currie.</h1>
      <br></br>
      <br></br>
      <br></br>
      <h3>I am a...</h3>
      <br></br>
      <h1>
      <Typewriter
        options={{
          strings: ['Software Engineer', "D&D Dungeon Master", " Trivia Host", "Climbing Aficionado", "L33T Gamer"],
          autoStart: true,
          loop: true,
        }}
      />
      </h1>
      <br></br>
      <Social/>
      <p class="scroll-down-link scroll-down-arrow" data-ca3_iconfont="ETmodules" data-ca3_icon=""></p>
      </div>
    </div>
  );
}