import { Link } from 'react-router-dom'
export default function BottomNav() {

  function homeScroll(){{window.scrollTo(0, 0)}}
  return (
    <div className='bottomNav'>
      <Link className='navLink' to='/'>
        <p onClick={homeScroll}>Home</p>
      </Link>
    </div>
  );
}