import { useEffect } from 'react';
import Logo from '../../images/squrlColor.png'
import BottomNav from './BottomNav'
import downloadGif from '../../images/download.gif'
import metricGif from '../../images/metrics.gif'

export default function Squrl() {

  useEffect(() => {window.scrollTo(0, 0)}, [])
  return (
    <div className="panel left">
      <div className='subpageWrapper'>
      <BottomNav />
        <a className="logo" target="_blank" rel="noopener noreferrer" href="https://www.squrldb.io">
        <img src={Logo} alt="SQURL" width='150px' overflow='hidden'/>
        </a>
        <br></br>
        <p>SQURL is a free, open-source desktop application with robust dummy data generation capabilities and a dynamic database KPI dashboard. It was created for developers as a tool to help with the design, implementation, and testing of databases together with their associated applications.</p>
        <br></br>
      <div className='gifs'>
        <img src={downloadGif} alt="SQURL Data Generation Demo"/>
        <br></br>
        <img src={metricGif} alt="SQURL database KPI Demo"/>
      </div>
      </div>
    </div>
  );
}